import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { Info as InfoIcon, Flag as FlagIcon, Star as StarIcon } from '@mui/icons-material';
import HeroSection from './HeroSection';
import BlogPosts from './BlogPosts';
import FeaturedHotels from './FeaturedHotels';
import Footer from './Footer';
import './HomePage.css';

const HomePage: React.FC = () => {
  return (
    <>
      <HeroSection />
      
      <BlogPosts />
      <FeaturedHotels />
      <Container>
        <Box className="about-section">
          <Typography variant="h4" component="h2" gutterBottom>
            <InfoIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} />
            About Us
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to Emerald Escape, your ultimate guide to the world's most luxurious casino hotels. We provide comprehensive reviews, ratings, and insights into the best gaming destinations around the globe. Our platform is designed to help you make informed decisions by offering detailed information on amenities, services, and unique features of each hotel.
          </Typography>
          <Typography variant="body1" paragraph>
            Whether you're a seasoned traveler or planning your first casino hotel experience, we aim to provide you with all the resources you need. Our team of experts is passionate about delivering the latest news and trends in the casino hotel industry, ensuring you stay updated with the best options available.
          </Typography>
        </Box>

        <Box className="mission-section">
          <Typography variant="h4" component="h2" gutterBottom>
            <FlagIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} />
            Our Mission
          </Typography>
          <Typography variant="body1" paragraph>
            Our mission is to help you discover the perfect casino hotel for your next getaway. Whether you're looking for high-stakes gaming, exquisite dining, or relaxing spa treatments, we have you covered. We believe in providing a seamless experience from browsing to booking, with user-friendly navigation and detailed hotel profiles.
          </Typography>
          <Typography variant="body1" paragraph>
            We are committed to showcasing a diverse range of hotels, from opulent resorts to charming boutique establishments. Our goal is to cater to all preferences and budgets, ensuring that every traveler finds their ideal destination.
          </Typography>
        </Box>

        <Box className="why-choose-us-section">
          <Typography variant="h4" component="h2" gutterBottom>
            <StarIcon sx={{ verticalAlign: 'middle', marginRight: '8px' }} />
            Why Choose Us?
          </Typography>
          <Typography variant="body1" paragraph>
            We offer unbiased reviews and ratings from real guests, ensuring you have all the information you need to make an informed decision. Our team of experts is dedicated to bringing you the latest news and trends in the casino hotel industry. We pride ourselves on transparency and accuracy, providing you with reliable data and insights.
          </Typography>
          <Typography variant="body1" paragraph>
            Our platform is designed with you in mind, offering personalized recommendations and curated lists of top-rated hotels. We also provide exclusive deals and promotions, helping you get the best value for your money.
          </Typography>
        </Box>

        <Box className="three-column-section">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom>
                Luxury Accommodations
              </Typography>
              <Typography variant="body1">
                Experience the epitome of luxury with our handpicked selection of casino hotels. Enjoy world-class amenities, stunning views, and unparalleled service that will make your stay unforgettable.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom>
                Exciting Entertainment
              </Typography>
              <Typography variant="body1">
                From live shows to thrilling casino games, our featured hotels offer a wide range of entertainment options. Discover vibrant nightlife, exclusive events, and endless fun at your fingertips.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom>
                Gourmet Dining
              </Typography>
              <Typography variant="body1">
                Indulge in exquisite culinary experiences with our curated list of restaurants. Savor gourmet dishes crafted by renowned chefs, offering a diverse array of flavors and cuisines.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
