import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Box, Avatar, Chip, Button, useTheme, useMediaQuery } from '@mui/material';
import { AccessTime as TimeIcon, ArrowForward as ArrowIcon, TimerOutlined as ReadTimeIcon } from '@mui/icons-material';
import './BlogPosts.css';
import { blogService, BlogPost } from '../services/blogService';

// Import images directly
import blogpost7237 from '../assets/images/blogpost7237.png';
import blogpost9234 from '../assets/images/blogpost9234.png';
import blogpost2341 from '../assets/images/blogpost2341.png';
import blogpost8722 from '../assets/images/blogpost8722.png';
import blogpost9824 from '../assets/images/blogpost9824.png';

// Map blog titles to their corresponding imported images
const BLOG_IMAGES: { [key: string]: string } = {
  "Top 5 Luxury Casino Resorts in Europe You Must Visit": blogpost9824,
  "The Ultimate Guide to High-Roller Suites in Nordic Casinos": blogpost9234,
  "Historic Casino Hotels: A Journey Through Europe's Gambling Heritage": blogpost2341,
  "Michelin Stars Meet Slot Machines: Best Casino Restaurant Experiences": blogpost8722,
  "Poker Paradise: Europe's Most Exclusive Poker Rooms": blogpost7237
};

const BlogPosts: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getImageForPost = (title: string) => {
    return BLOG_IMAGES[title];
  };

  const truncateText = (text: string, wordLimit: number) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(' ')}...`;
    }
    return text;
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const data = await blogService.getAllPosts();
        const sortedPosts = data.sort((a, b) => 
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setPosts(sortedPosts);
      } catch (err) {
        setError('Failed to fetch blog posts');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const displayPosts = isMobile ? posts.slice(0, 1) : posts.slice(0, 3);

  return (
    <Box className="blog-section">
      <Container>
        <Grid container spacing={4}>
          {displayPosts.map(post => (
            <Grid item xs={12} md={4} key={post._id}>
              <Card className="blog-card">
                <CardMedia
                  component="img"
                  height="200"
                  image={getImageForPost(post.title)}
                  alt={post.title}
                  className="blog-image"
                />
                <CardContent className="blog-content">
                  <Typography variant="h5" component="h3" className="blog-title">
                    {truncateText(post.title, 10)}
                  </Typography>
                  
                  <Box className="blog-meta">
                    <Box className="post-info">
                      <Chip 
                        icon={<TimeIcon />} 
                        label={new Date(post.createdAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })} 
                        size="small" 
                        variant="outlined"
                        className="info-chip"
                      />
                      <Chip 
                        icon={<ReadTimeIcon />}
                        label="5 min read" 
                        size="small" 
                        variant="outlined"
                        className="info-chip"
                      />
                    </Box>
                  </Box>

                  <Typography variant="body1" className="blog-excerpt">
                    {truncateText(post.content, 15)}
                  </Typography>
                  
                  <Box className="blog-footer">
                    <Box className="author-info">
                      <Avatar className="author-avatar">
                        {post.author.username.charAt(0).toUpperCase()}
                      </Avatar>
                      <Box className="author-details">
                        <Typography variant="subtitle2">{post.author.username}</Typography>
                      </Box>
                    </Box>
                    <Button 
                      variant="text" 
                      endIcon={<ArrowIcon />}
                      href={`/blog/${post.slug}`}
                      className="read-more-btn"
                    >
                      Read More
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default BlogPosts; 