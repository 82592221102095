import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, Box } from '@mui/material';
import { hotelService, Hotel } from '../services/hotelService';
import HotelCard from './HotelCard';
import { Casino as CasinoIcon, Stars as StarsIcon } from '@mui/icons-material';

const FeaturedHotels: React.FC = () => {
    const [hotels, setHotels] = useState<Hotel[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchHotels = async () => {
            try {
                const data = await hotelService.getFeaturedHotels(6);
                
                if (Array.isArray(data)) {
                    setHotels(data);
                } else {
                    console.error('Expected an array but got:', typeof data, data);
                    setError('Failed to fetch featured hotels');
                }
            } catch (err) {
                setError('Failed to fetch featured hotels');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchHotels();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Box sx={{ py: 6 }}>
            <Container>
                <Typography variant="h4" component="h2" gutterBottom sx={{ marginBottom: "55px" }}>
                    <StarsIcon sx={{ marginRight: "3px", fontSize: "32px", marginBottom: "-3px", color: "darkgrey" }}/> 
                    Featured Casino Hotels
                </Typography>
                <Grid container spacing={4}>
                    {hotels.map(hotel => (
                        <Grid item xs={12} md={4} key={hotel._id}>
                            <HotelCard hotel={hotel} showReviewQuote={true} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default FeaturedHotels;