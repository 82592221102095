export enum CountryCode {
    // Nordic
    SE = "SE", // Sweden
    NO = "NO", // Norway
    DK = "DK", // Denmark
    FI = "FI", // Finland
    IS = "IS", // Iceland

    // Western Europe
    FR = "FR", // France
    DE = "DE", // Germany
    UK = "UK", // United Kingdom
    IE = "IE", // Ireland
    BE = "BE", // Belgium
    NL = "NL", // Netherlands
    LU = "LU", // Luxembourg
    CH = "CH", // Switzerland
    AT = "AT", // Austria

    // Southern Europe
    ES = "ES", // Spain
    PT = "PT", // Portugal
    IT = "IT", // Italy
    GR = "GR", // Greece
    MT = "MT", // Malta

    // Central & Eastern Europe
    PL = "PL", // Poland
    CZ = "CZ", // Czech Republic
    SK = "SK", // Slovakia
    HU = "HU", // Hungary
    RO = "RO", // Romania
    HR = "HR", // Croatia
    SI = "SI", // Slovenia
    EE = "EE", // Estonia
    LV = "LV", // Latvia
    LT = "LT"  // Lithuania
}

export const CountryNames: Record<CountryCode, string> = {
    SE: "Sweden",
    NO: "Norway",
    DK: "Denmark",
    FI: "Finland",
    IS: "Iceland",
    FR: "France",
    DE: "Germany",
    UK: "United Kingdom",
    IE: "Ireland",
    BE: "Belgium",
    NL: "Netherlands",
    LU: "Luxembourg",
    CH: "Switzerland",
    AT: "Austria",
    ES: "Spain",
    PT: "Portugal",
    IT: "Italy",
    GR: "Greece",
    MT: "Malta",
    PL: "Poland",
    CZ: "Czech Republic",
    SK: "Slovakia",
    HU: "Hungary",
    RO: "Romania",
    HR: "Croatia",
    SI: "Slovenia",
    EE: "Estonia",
    LV: "Latvia",
    LT: "Lithuania"
};

export enum CasinoType {
    LUXURY = "LUXURY",
    BOUTIQUE = "BOUTIQUE",
    RESORT = "RESORT",
    HISTORIC = "HISTORIC"
}

interface ICasinoServices {
    valet: boolean;
    foodService: string;
    complimentaryDrinks: boolean;
    smokingArea: string;
    tableReservations: boolean;
    gamesTutorials: boolean;
    wheelchairAccess: boolean;
}

interface ITableLimits {
    min: number;
    max: number;
}

interface IGamingFloor {
    totalFloors: number;
    smokingFloor: boolean;
    privateRooms: number;
    tableLimits: {
        blackjack?: ITableLimits;
        roulette?: ITableLimits;
        poker?: ITableLimits;
        baccarat?: ITableLimits;
        craps?: ITableLimits;
        paiGow?: ITableLimits;
    };
}

interface ISlotMachines {
    progressiveJackpots: number;
    videoPoker: number;
    classicSlots: number;
    modernSlots: number;
    electronicTableGames: number;
    minimumDenomination: number;
    maximumDenomination: number;
}

interface IPokerRoomDetails {
    tables: number;
    tournaments: {
        daily: boolean;
        weekly: boolean;
        monthly: boolean;
        special: string;
    };
    cashGames: {
        types: string[];
        stakes: {
            minimum: string;
            maximum: string;
        };
    };
    amenities: string[];
}

interface IRestaurantDetails {
    fine: number;
    casual: number;
    buffet: number;
    cafes: number;
    specialties: string[];
}

export interface ICasinoDetails {
    totalGamingSpace: number;
    numberOfSlots: number;
    numberOfTables: number;
    pokerRoom: boolean;
    pokerTables?: number;
    minimumBet: number;
    maximumBet: number;
    games: string[];
    vipLounges: number;
    restaurants: number;
    bars: number;
    entertainmentVenues: number;
    openingHours: {
        opens: string;
        closes: string;
        isOpenAllDay: boolean;
    };
    dresscode: string;
    loyaltyProgram: boolean;
    specialFeatures: string[];
    yearEstablished: number;
    lastRenovated?: number;
    hasLiveEntertainment: boolean;
    hasSportsbook: boolean;
    minimumAge: number;
    currencies: string[];
    casinoServices: ICasinoServices;
    gamingFloor: IGamingFloor;
    slotMachines: ISlotMachines;
    pokerRoomDetails: IPokerRoomDetails;
    restaurantDetails: IRestaurantDetails;
    entertainmentTypes: string[];
    securityFeatures: string[];
}

export interface IHotel {
    _id: string;
    name: string;
    description: string;
    address: string;
    country: CountryCode;
    averageRating: number;
    isTopPick: boolean;
    casinoType: CasinoType;
    slug: string;
    casino: ICasinoDetails;
    priceRange: {
        min: number;
        max: number;
        currency: string;
    };
    amenities: string[];
    roomCount: number;
    createdAt: Date;
    updatedAt: Date;
} 