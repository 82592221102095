import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Avatar, Chip, Paper } from '@mui/material';
import { AccessTime as TimeIcon } from '@mui/icons-material';
import { blogService, BlogPost } from '../services/blogService';
import './BlogPostPage.css';
import { useSecurity } from '../contexts/SecurityContext';

// Import images directly
import blogpost7237 from '../assets/images/blogpost7237.png';
import blogpost9234 from '../assets/images/blogpost9234.png';
import blogpost2341 from '../assets/images/blogpost2341.png';
import blogpost8722 from '../assets/images/blogpost8722.png';
import blogpost9824 from '../assets/images/blogpost9824.png';

// Map blog titles to their corresponding imported images
const BLOG_IMAGES: { [key: string]: string } = {
  "Top 5 Luxury Casino Resorts in Europe You Must Visit": blogpost9824,
  "The Ultimate Guide to High-Roller Suites in Nordic Casinos": blogpost9234,
  "Historic Casino Hotels: A Journey Through Europe's Gambling Heritage": blogpost2341,
  "Michelin Stars Meet Slot Machines: Best Casino Restaurant Experiences": blogpost8722,
  "Poker Paradise: Europe's Most Exclusive Poker Rooms": blogpost7237
};

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = React.useState<BlogPost | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const { sanitizeHTML } = useSecurity();

  const getImageForPost = (title: string | undefined) => {
    return title ? BLOG_IMAGES[title] : '';
  };

  const createMarkup = (htmlContent: string) => {
    return { __html: sanitizeHTML(htmlContent) };
  };

  React.useEffect(() => {
    const fetchPost = async () => {
      if (!slug) {
        setError('No slug provided');
        setLoading(false);
        return;
      }

      try {
        const data = await blogService.getPostBySlug(slug);
        setPost(data);
      } catch (err) {
        setError('Failed to fetch blog post');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <Box className="blog-post-page">
      <Container>
        <Box className="blog-post-container">
          <Paper elevation={3} className="blog-post-sidebar">
            <Box
              component="img"
              src={getImageForPost(post.title)}
              alt={post.title}
              className="blog-post-image"
            />
            <Box className="blog-post-meta">
              <Box className="author-info">
                <Avatar className="author-avatar">
                  {post.author.username.charAt(0).toUpperCase()}
                </Avatar>
                <Typography variant="subtitle2" className="author-name">
                  {post.author.username}
                </Typography>
              </Box>
              <Chip
                icon={<TimeIcon />}
                label={new Date(post.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
                size="small"
                variant="outlined"
                className="info-chip"
              />
            </Box>
          </Paper>
          
          <Box className="blog-post-content">
            <Typography variant="h2" component="h1" className="blog-post-title">
              {post.title}
            </Typography>
            <Box dangerouslySetInnerHTML={createMarkup(post.content)} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default BlogPostPage;