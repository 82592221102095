import axios from 'axios';
import API_BASE_URL from '../apiConfig';

const API_URL = `${API_BASE_URL}`;

export interface BlogPost {
  _id: string;
  title: string;
  content: string;
  author: {
    _id: string;
    username: string;
  };
  createdAt: string;
  updatedAt: string;
  slug: string;
}

export const blogService = {
  async getAllPosts(): Promise<BlogPost[]> {
    try {
      
      const response = await axios.get(`${API_URL}/blog`);
      
      return response.data;
    } catch (error) {
     
      if (axios.isAxiosError(error)) {
       
      }
      throw error;
    }
  },

  async getPostBySlug(slug: string): Promise<BlogPost> {
    const response = await axios.get(`${API_URL}/blog/${slug}`);
    return response.data;
  },

  async createPost(postData: Partial<BlogPost>): Promise<BlogPost> {
    const response = await axios.post(`${API_URL}/blog`, postData);
    return response.data;
  },

  async updatePost(id: string, postData: Partial<BlogPost>): Promise<BlogPost> {
    const response = await axios.put(`${API_URL}/blog/${id}`, postData);
    return response.data;
  },

  async deletePost(id: string): Promise<void> {
    await axios.delete(`${API_URL}/blog/${id}`);
  }
}; 