import React, { createContext, useContext } from 'react';
import DOMPurify from 'dompurify';

interface SecurityContextType {
  sanitizeHTML: (dirty: string) => string;
}

const SecurityContext = createContext<SecurityContextType | undefined>(undefined);

export const SecurityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const sanitizeHTML = (dirty: string) => {
    return DOMPurify.sanitize(dirty, {
      ALLOWED_TAGS: [
        'p', 'b', 'i', 'em', 'strong', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'ul', 'ol', 'li', 'br', 'div', 'span', 'img'
      ],
      ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'class'],
      USE_PROFILES: { html: true }
    });
  };

  return (
    <SecurityContext.Provider value={{ sanitizeHTML }}>
      {children}
    </SecurityContext.Provider>
  );
};

export const useSecurity = () => {
  const context = useContext(SecurityContext);
  if (context === undefined) {
    throw new Error('useSecurity must be used within a SecurityProvider');
  }
  return context;
}; 