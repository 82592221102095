import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Link,
  Drawer,
  IconButton,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  AutoAwesomeOutlined as LogoIcon,
  Casino as CasinoIcon,
  LocalOffer as OffersIcon,
  Article as BlogIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  AccountCircle as AccountIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './Navbar.css';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user, logout } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
    navigate('/');
  };

  const authButtons = isAuthenticated ? (
    <>
      <Button
        color="inherit"
        onClick={handleMenuOpen}
        startIcon={<AccountIcon />}
        className="user-menu-button"
      >
        {user?.username}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="user-menu"
      >
        
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <Button
        color="inherit"
        variant="outlined"
        startIcon={<PersonIcon />}
        sx={{ mr: 1 }}
        className="login-btn"
        href="/login"
      >
        Login
      </Button>
      <Button
        color="secondary"
        variant="contained"
        startIcon={<PersonIcon />}
        className="register-btn"
        href="/register"
      >
        Register
      </Button>
    </>
  );

  const mobileMenu = (
    <Box className="mobile-menu">
      <Box className="mobile-menu-header">
        <IconButton onClick={handleDrawerToggle} className="close-button">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="mobile-menu-content">
        <Button
          fullWidth
          color="inherit"
          href="/hotels"
          startIcon={<CasinoIcon />}
        >
          Casino Hotels
        </Button>
        <Button
          fullWidth
          color="inherit"
          href="/offers"
          startIcon={<OffersIcon />}
        >
          Offers
        </Button>
        <Button
          fullWidth
          color="inherit"
          href="/blog"
          startIcon={<BlogIcon />}
        >
          Blog
        </Button>
        {isAuthenticated ? (
          <>
           
            <Button
              fullWidth
              color="inherit"
              onClick={handleLogout}
              startIcon={<PersonIcon />}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              startIcon={<PersonIcon />}
              className="login-btn"
              href="/login"
            >
              Login
            </Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              startIcon={<PersonIcon />}
              className="register-btn"
              href="/register"
            >
              Register
            </Button>
          </>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky" className="navbar">
        <Toolbar>
          {/* Logo and Brand */}
          <Typography variant="h6" className="brand-logo">
            <Link href="/" underline="none" color="inherit">
              <LogoIcon className="logo-icon" />
              Emerald Escape
            </Link>
          </Typography>

          {/* Desktop Navigation */}
          <Box className="desktop-menu">
            <Button 
              color="inherit" 
              href="/hotels"
              startIcon={<CasinoIcon />}
              sx={{ mr: 2 }}
            >
              Casino Hotels
            </Button>
            <Button 
              color="inherit" 
              href="/offers"
              startIcon={<OffersIcon />}
              sx={{ mr: 2 }}
            >
              Offers
            </Button>
            <Button 
              color="inherit" 
              href="/blog"
              startIcon={<BlogIcon />}
              sx={{ mr: 2 }}
            >
              Blog
            </Button>
            {authButtons}
          </Box>

          {/* Mobile Menu Button */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className="mobile-menu-button"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className="mobile-drawer"
        ModalProps={{
          keepMounted: true, // Better mobile performance
        }}
      >
        {mobileMenu}
      </Drawer>
    </>
  );
};

export default Navbar; 