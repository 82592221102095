// Import all images
import hotel1234567 from '../assets/images/1234567.png';
import hotel2345678 from '../assets/images/2345678.png';
import hotel3456721 from '../assets/images/3456721.png';
import hotel4629583 from '../assets/images/4629583.png';
import hotel4938162 from '../assets/images/4938162.png';
import hotel5129347 from '../assets/images/5129347.png';
import hotel5837201 from '../assets/images/5837201.png';
import hotel6192837 from '../assets/images/6192837.png';
import hotel7029438 from '../assets/images/7029438.png';
import hotel7182934 from '../assets/images/7182934.png';
import hotel7294168 from '../assets/images/7294168.png';
import hotel7831924 from '../assets/images/7831924.png';
import hotel8193452 from '../assets/images/8193452.png';
import hotel8293716 from '../assets/images/8293716.png';
import hotel8392714 from '../assets/images/8392714.png';
import hotel8451932 from '../assets/images/8451932.png';
import hotel8572391 from '../assets/images/8572391.png';
import hotel8643297 from '../assets/images/8643297.png';
import hotel8791254 from '../assets/images/8791254.png';
import hotel8923741 from '../assets/images/8923741.png';
import hotel9137285 from '../assets/images/9137285.png';
import hotel9248167 from '../assets/images/9248167.png';
import hotel9312847 from '../assets/images/9312847.png';
import hotel9473628 from '../assets/images/9473628.png';
import hotel9537182 from '../assets/images/9537182.png';
import hotel9617425 from '../assets/images/9617425.png';
import hotel9725314 from '../assets/images/9725314.png';
import hotel9846213 from '../assets/images/9846213.png';
import hotel9871234 from '../assets/images/9871234.png';
import hotel9913472 from '../assets/images/9913472.png';
import hotel9937182 from '../assets/images/9937182.png';
import hotel9984721 from '../assets/images/9984721.png';
import hotel5463728 from '../assets/images/5463728.png';
import hotel6592784 from '../assets/images/6592784.png';
import hotel7126489 from '../assets/images/7126489.png';
import hotel7826493 from '../assets/images/7826493.png';
import hotel8364912 from '../assets/images/8364912.png';
import hotel9164278 from '../assets/images/9164278.png';
import hotel9481627 from '../assets/images/9481627.png';
import hotel9876432 from '../assets/images/9876432.png';

// Map hotel names to their corresponding imported images
export const HOTEL_IMAGES: { [key: string]: string } = {
  "Silverdew Moon Cavern": hotel9137285,
  "Ravenwing Glass Estate": hotel6192837,
  "Bramble Ash Citadel": hotel5837201,
  "Cobalt Ember Pavilion": hotel5129347,
  "Whispering Juniper Terrace": hotel4938162,
  "Frostsong Lichen Grove": hotel4629583,
  "Opaline Mirage Haven": hotel3456721,
  "Starcrest Dune Forge": hotel2345678,
  "Ebonleaf Shimmer Hall": hotel1234567,
  "Crystalline Mirage Palace": hotel9876432,
  "Velvet Sands Royal Retreat": hotel9481627,
  "Moonstone Vale Hideaway": hotel9164278,
  "Skyward Drifts Oasis": hotel8643297,
  "Emerald Fjord Haven": hotel7831924,
  "Whispering Pine Groves": hotel8923741,
  "Glacial Pearl Sanctuary": hotel9312847,
  "Marble Echo Terrace": hotel8572391,
  "Opaline Dusk Pavilion": hotel7294168,
  "Ruby Lattice Grotto": hotel9537182,
  "Opal Mist Citadel": hotel9725314,
  "Twilight Amber Retreat": hotel7826493,
  "Azure Conch Pavilion": hotel7126489,
  "Crystal Finch Heights": hotel6592784,
  "Moonlit Ember Estate": hotel5463728,
  "Amberleaf Ivy Hotel": hotel9984721,
  "Silent Orchid Refuge": hotel8451932,
  "Garnet Wing Abbey": hotel9937182,
  "Rosette Whisper Spire": hotel8293716,
  "Silken Vale Luminary": hotel9871234,
  "Thornberry Cloud Crest": hotel8791254,
  "Indigo Falls Chateau": hotel9248167,
  "Jade Ember Isle": hotel7029438,
  "Saffron Wing Arcadia": hotel9617425,
  "Windsong Silver Gate": hotel8193452,
  "Lunar Echo Aerie": hotel9846213,
  "Pearlescent Glade Manor": hotel7182934,
  "Crimson Rune Gardens": hotel9473628,
  "Ivory Echo Haven": hotel8364912,
  "Blueberry Ridge Manor": hotel9913472,
  "Frosted Pine Sundown": hotel8392714
}; 