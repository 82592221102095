import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Card, CardMedia, CardContent, Typography, Box, Chip, Button } from '@mui/material';
import { SportsEsports as CasinoIcon, Star as StarIcon, EuroSymbol as EuroIcon, ArrowForward as ArrowIcon } from '@mui/icons-material';
import './HotelCard.css';
import { CountryNames, CasinoType } from '../types/hotel';
import { Hotel, Review } from '../services/hotelService';
import { HOTEL_IMAGES } from '../assets/hotelImages';

interface HotelCardProps {
  hotel: Hotel;
  showReviewQuote?: boolean;
}

const HotelCard: React.FC<HotelCardProps> = ({ hotel, showReviewQuote = false }) => {
  const formatCasinoType = (type: CasinoType) => {
    return type.charAt(0) + type.slice(1).toLowerCase();
  };

  const getHighlightFeatures = (hotel: Hotel) => {
    const features = [];
    
    if (hotel.casino.pokerRoom) {
      features.push('Poker Room');
    }
    if (hotel.casino.hasLiveEntertainment) {
      features.push('Live Entertainment');
    }
    if (hotel.casino.vipLounges > 0) {
      features.push(`${hotel.casino.vipLounges} VIP Lounges`);
    }
    if (hotel.casino.restaurants > 0) {
      features.push(`${hotel.casino.restaurants} Restaurants`);
    }
    
    return features.slice(0, 3); // Return top 3 features
  };

  const getHighlightedReview = (reviews: Review[] | undefined) => {
    if (!reviews || reviews.length === 0) return null;
    
    // First try to find a 5-star review
    for (let rating = 5; rating >= 1; rating--) {
        const reviewsWithRating = reviews.filter(r => r.rating === rating);
        if (reviewsWithRating.length > 0) {
            return reviewsWithRating[Math.floor(Math.random() * reviewsWithRating.length)];
        }
    }
    return null;
  };

  const highlightedReview = hotel.reviews ? getHighlightedReview(hotel.reviews) : null;
  const reviewCount = hotel.reviews?.length || 0;

  const imageFilename = HOTEL_IMAGES[hotel.name];

  // Get full country name
  const getCountryName = (countryCode: string) => {
    return new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode) || hotel.country;
  };

  return (
    <Card className="hotel-card">
      <CardMedia
        component="img"
        height="200"
        image={imageFilename}
        alt={hotel.name}
        className="hotel-image"
      />
      <CardContent className="hotel-content">
        <div className="hotel-header">
          <Typography variant="h6" className="hotel-title">
            {hotel.name}
          </Typography>
          <div className="rating-box">
            <StarIcon fontSize="small" className="star-icon" />
            <Typography variant="body2">
              {hotel.averageRating?.toFixed(1) || 'N/A'}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
              ({reviewCount} reviews)
            </Typography>
          </div>
        </div>

        <div className="hotel-location">
          <div className="country-info">
            <ReactCountryFlag 
              countryCode={hotel.country} 
              svg 
              className="country-flag"
            />
            <Typography variant="body2" color="text.secondary">
              {getCountryName(hotel.country)}
            </Typography>
          </div>
          <div className="casino-type">
            <CasinoIcon className="casino-icon" />
            <Typography variant="body2" color="text.secondary">
              {hotel.casinoType}
            </Typography>
          </div>
        </div>

        <Box className="hotel-highlights">
          {getHighlightFeatures(hotel).map((feature, index) => (
            <Chip
              key={index}
              label={feature}
              size="small"
              className="feature-chip"
            />
          ))}
        </Box>

        {showReviewQuote && highlightedReview && (
          <Box className="highlighted-review">
            <Typography className="review-quote">
              "{highlightedReview.comment}"
            </Typography>
            <Typography className="review-author" align="right">
              - {highlightedReview.user?.username || 'Anonymous'}
            </Typography>
          </Box>
        )}

        <Box className="hotel-footer">
          <Box className="price-info">
            <Typography variant="caption" color="text.secondary">
              From
            </Typography>
            <Typography variant="h6" className="price">
              {hotel.priceRange?.min || 'N/A'} {hotel.priceRange?.currency}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              per night
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            endIcon={<ArrowIcon />}
            href={`/hotels/${hotel.slug}`}
            className="view-details-btn"
          >
            View Details
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default HotelCard; 