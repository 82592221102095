import axios from 'axios';
import { CasinoType, CountryCode } from '../types/hotel';
import API_BASE_URL from '../apiConfig';

const API_URL = `${API_BASE_URL}`;

export interface Review {
    _id: string;
    rating: number;
    comment: string;
    user: {
        _id: string;
        username: string;
    };
    hotel: string;
    createdAt: string;
    updatedAt: string;
}

// Base Hotel interface for card views
export interface Hotel {
    _id: string;
    name: string;
    country: CountryCode;
    description: string;
    averageRating: number;
    isTopPick: boolean;
    casinoType: CasinoType;
    slug: string;
    casino: {
        pokerRoom: boolean;
        hasLiveEntertainment: boolean;
        vipLounges: number;
        restaurants: number;
        totalGamingSpace: number;
        numberOfTables: number;
        numberOfSlots: number;
    };
    priceRange: {
        min: number;
        max: number;
        currency: string;
    };
    reviews: Review[];
}

interface IPokerRoomDetails {
  tables: number;
  cashGames: {
    types: string[];
    stakes: {
      minimum: number;
      maximum: number;
    };
  };
  tournaments: {
    daily: boolean;
    weekly: boolean;
    monthly: boolean;
    special?: string;
  };
  amenities: string[];
}

interface ISlotMachines {
  progressiveJackpots: number;
  videoPoker: number;
  classicSlots: number;
  modernSlots: number;
  electronicTableGames: number;
  minimumDenomination: number;
  maximumDenomination: number;
}

interface IRestaurantDetails {
  fine: number;
  casual: number;
  buffet: number;
  cafes: number;
  specialties: string[];
}

// Extended Hotel interface for detailed view
export interface HotelDetail extends Hotel {
    address: string;
    amenities: string[];
    roomCount: number;
    createdAt: Date;
    updatedAt: Date;
    casino: {
        totalGamingSpace: number;
        numberOfSlots: number;
        numberOfTables: number;
        pokerRoom: boolean;
        pokerTables?: number;
        minimumBet: number;
        maximumBet: number;
        games: string[];
        vipLounges: number;
        restaurants: number;
        bars: number;
        entertainmentVenues: number;
        openingHours: {
            opens: string;
            closes: string;
            isOpenAllDay: boolean;
        };
        dresscode: string;
        loyaltyProgram: boolean;
        specialFeatures: string[];
        yearEstablished: number;
        lastRenovated?: number;
        hasLiveEntertainment: boolean;
        hasSportsbook: boolean;
        minimumAge: number;
        currencies: string[];
        slotMachines: ISlotMachines;
        pokerRoomDetails: IPokerRoomDetails;
        restaurantDetails: IRestaurantDetails;
    };
}

export const hotelService = {
    getFeaturedHotels: async (limit: number = 6): Promise<Hotel[]> => {
        try {
            const response = await axios.get(`${API_URL}/hotels`, {
                params: { limit }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching featured hotels:', error);
            throw error;
        }
    },

    getAllHotels: async (): Promise<Hotel[]> => {
        try {
            const response = await axios.get(`${API_URL}/hotels`);
            return response.data;
        } catch (error) {
            console.error('Error fetching all hotels:', error);
            throw error;
        }
    },

    getTopHotelsByCountry: async (country: CountryCode): Promise<Hotel[]> => {
        try {
            const response = await axios.get(`${API_URL}/hotels/country/${country}/top`);
            return response.data;
        } catch (error) {
            console.error('Error fetching top hotels by country:', error);
            throw error;
        }
    },

    getHotelBySlug: async (slug: string): Promise<HotelDetail> => {
        try {
            const response = await axios.get(`${API_URL}/hotels/by-slug/${slug}`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.code === 'ERR_CONNECTION_REFUSED') {
                    throw new Error('Unable to connect to the server. Please make sure the backend is running.');
                }
                throw new Error(error.response?.data?.error || 'Failed to fetch hotel details');
            }
            throw error;
        }
    }
}; 