import React from 'react';
import { Typography, Container, Box, Button } from '@mui/material';
import { ArrowForward as ArrowIcon } from '@mui/icons-material';
import heroImage from '../assets/images/rooftop_casino_lounge.png';
import './HeroSection.css';

const HeroSection: React.FC = () => {
  return (
    <Box 
      className="hero-section" 
      sx={{ 
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${heroImage})`,
        backgroundPosition: 'center 45%'
      }}
    >
      <Container maxWidth="md">
        <Box className="hero-content">
          <Typography variant="h3" component="h1" gutterBottom className="hero-title" align="center">
            Discover The Best Casino Hotels
          </Typography>
          <Typography variant="h6" component="p" gutterBottom className="hero-subtitle" align="center">
            Explore world-class gaming destinations, exclusive getaways, and unforgettable moments. 
            From opulent resorts to boutique casino hotels, find your perfect escape.
          </Typography>
          <Box className="hero-cta-container">
            <Button 
              variant="contained" 
              size="large" 
              className="hero-cta"
              endIcon={<ArrowIcon />}
              href="/hotels"
            >
              Start Your Journey
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroSection; 