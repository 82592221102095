import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Typography, 
  Paper, 
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import {
  Casino as CasinoIcon,
  Restaurant as RestaurantIcon,
  LocalBar as BarIcon,
  Timer as TimerIcon,
  Hotel as HotelIcon,
  Star as StarIcon,
  AttachMoney as MoneyIcon,
  EventSeat as EventIcon,
  RateReview as ReviewIcon,
} from '@mui/icons-material';
import { CountryNames } from '../types/hotel';
import { HOTEL_IMAGES } from '../assets/hotelImages';
import { hotelService, HotelDetail } from '../services/hotelService';
import './HotelDetailPage.css';

const HotelDetailPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [hotel, setHotel] = useState<HotelDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchHotelDetails = async () => {
      try {
        setLoading(true);
        if (slug) {
          const hotelData = await hotelService.getHotelBySlug(slug);
          setHotel(hotelData);
        }
      } catch (err) {
        setError('Failed to load hotel details');
        console.error('Error loading hotel:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchHotelDetails();
  }, [slug]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Container className="loading-container">
        <CircularProgress />
      </Container>
    );
  }

  if (error || !hotel) {
    return (
      <Container className="error-container">
        <Typography color="error">{error || 'Hotel not found'}</Typography>
      </Container>
    );
  }

  const imageUrl = HOTEL_IMAGES[hotel.name];

  return (
    <>
      <div className="hotel-hero">
        <img 
          src={imageUrl} 
          alt={hotel.name}
          className="hotel-hero-image"
        />
        <div className="hotel-hero-overlay">
          <div className="hotel-hero-content">
            <Typography variant="h3" component="h1" className="hotel-hero-title">
              {hotel.name}
            </Typography>
            <div className="hotel-hero-location">
              <div className="location-chip">
                <ReactCountryFlag countryCode={hotel.country} svg />
                <Typography>{CountryNames[hotel.country]}</Typography>
              </div>
              <div className="hero-chip">
                <CasinoIcon sx={{ color: 'white' }} />
                <Typography>{hotel.casinoType}</Typography>
              </div>
              <div className="rating-chip">
                <StarIcon />
                <Typography>
                  {hotel.averageRating.toFixed(1)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container maxWidth="lg" className="hotel-detail-container">
        <Paper className="tabs-section">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            className="detail-tabs"
          >
            <Tab 
              icon={<CasinoIcon />} 
              iconPosition="start" 
              label="Casino Information" 
            />
            <Tab 
              icon={<ReviewIcon />} 
              iconPosition="start" 
              label="Reviews" 
            />
          </Tabs>
          
          {/* Casino Information Tab */}
          {activeTab === 0 && (
            <div className="tab-content">
              <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                  <Paper className="hotel-section overview-section">
                    <Typography variant="h5" gutterBottom>Overview</Typography>
                    <Typography paragraph className="description-text">
                      {hotel.description}
                    </Typography>
                    <div className="hotel-amenities">
                      {hotel.amenities.map((amenity, index) => (
                        <Chip key={index} label={amenity} size="small" className="amenity-chip" />
                      ))}
                    </div>
                  </Paper>

                  <Paper className="hotel-section casino-section">
                    <Typography variant="h5" gutterBottom>Casino Details</Typography>
                    
                    {/* General Info Row */}
                    <TableContainer className="details-table">
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className="detail-label">Gaming Space</TableCell>
                            <TableCell className="detail-value">{hotel.casino.totalGamingSpace.toLocaleString()}m²</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-label">Gaming Tables</TableCell>
                            <TableCell className="detail-value">{hotel.casino.numberOfTables}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-label">Betting Range</TableCell>
                            <TableCell className="detail-value">
                              {hotel.casino.minimumBet} - {hotel.casino.maximumBet} {hotel.priceRange.currency}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="detail-label">Currencies Accepted</TableCell>
                            <TableCell className="detail-value">{hotel.casino.currencies.join(', ')}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* Slot Machines Section */}
                    <div className="casino-subsection">
                      <Typography variant="subtitle1" gutterBottom align="center">Slot Machines</Typography>
                      <TableContainer className="details-table">
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell className="detail-label">Progressive Jackpots</TableCell>
                              <TableCell className="detail-value">{hotel.casino.slotMachines.progressiveJackpots}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-label">Video Poker</TableCell>
                              <TableCell className="detail-value">{hotel.casino.slotMachines.videoPoker}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-label">Classic Slots</TableCell>
                              <TableCell className="detail-value">{hotel.casino.slotMachines.classicSlots}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-label">Modern Slots</TableCell>
                              <TableCell className="detail-value">{hotel.casino.slotMachines.modernSlots}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="detail-label">Electronic Table Games</TableCell>
                              <TableCell className="detail-value">{hotel.casino.slotMachines.electronicTableGames}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Denomination Range</TableCell>
                              <TableCell>
                                {hotel.casino.slotMachines.minimumDenomination} - {hotel.casino.slotMachines.maximumDenomination} {hotel.priceRange.currency}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    {/* Poker Room Section */}
                    {hotel.casino.pokerRoom && (
                      <div className="casino-subsection">
                        <Typography variant="subtitle1" gutterBottom align="center">Poker Room</Typography>
                        <TableContainer className="details-table">
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell>Number of Tables</TableCell>
                                <TableCell>{hotel.casino.pokerRoomDetails.tables}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Cash Games</TableCell>
                                <TableCell>
                                  <div>Types: {hotel.casino.pokerRoomDetails.cashGames.types.join(', ')}</div>
                                  <div>Stakes: {hotel.casino.pokerRoomDetails.cashGames.stakes.minimum} - {hotel.casino.pokerRoomDetails.cashGames.stakes.maximum}</div>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Tournaments</TableCell>
                                <TableCell>
                                  {hotel.casino.pokerRoomDetails.tournaments.daily && <div>• Daily Tournaments</div>}
                                  {hotel.casino.pokerRoomDetails.tournaments.weekly && <div>• Weekly Tournaments</div>}
                                  {hotel.casino.pokerRoomDetails.tournaments.monthly && <div>• Monthly Tournaments</div>}
                                  {hotel.casino.pokerRoomDetails.tournaments.special && <div>• Special Events: {hotel.casino.pokerRoomDetails.tournaments.special}</div>}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Amenities</TableCell>
                                <TableCell>{hotel.casino.pokerRoomDetails.amenities.join(', ')}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}

                    {/* Restaurant Details */}
                    <div className="casino-subsection">
                      <Typography variant="subtitle1" gutterBottom align="center">Dining Options</Typography>
                      <TableContainer className="details-table">
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>Fine Dining</TableCell>
                              <TableCell>{hotel.casino.restaurantDetails.fine} restaurants</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Casual Dining</TableCell>
                              <TableCell>{hotel.casino.restaurantDetails.casual} restaurants</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Buffets</TableCell>
                              <TableCell>{hotel.casino.restaurantDetails.buffet}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Cafes</TableCell>
                              <TableCell>{hotel.casino.restaurantDetails.cafes}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Specialties</TableCell>
                              <TableCell>{hotel.casino.restaurantDetails.specialties.join(', ')}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    {/* Special Features Row */}
                    <div className="features-section">
                      <Typography variant="subtitle1" gutterBottom align="center">Special Features</Typography>
                      <div className="features-grid">
                        {hotel.casino.specialFeatures.map((feature, index) => (
                          <Chip 
                            key={index}
                            label={feature}
                            size="small"
                            className="feature-chip"
                          />
                        ))}
                      </div>
                    </div>
                    
                    {/* Games Row */}
                    <div className="games-divider">
                      <Typography variant="subtitle1" gutterBottom align="center">Available Games</Typography>
                      <div className="games-grid">
                        {hotel.casino.games.map((game, index) => (
                          <Chip 
                            key={index} 
                            label={game} 
                            className="game-chip"
                            icon={<CasinoIcon className="game-icon" />}
                          />
                        ))}
                      </div>
                    </div>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Paper className="hotel-section quick-info-section">
                    <Typography variant="h6" gutterBottom>Quick Information</Typography>
                    <div className="info-grid">
                      <div className="quick-info-item">
                        <TimerIcon />
                        <Typography>
                          {hotel.casino.openingHours.isOpenAllDay 
                            ? 'Open 24/7'
                            : `${hotel.casino.openingHours.opens} - ${hotel.casino.openingHours.closes}`
                          }
                        </Typography>
                      </div>
                      <div className="quick-info-item">
                        <RestaurantIcon />
                        <Typography>{hotel.casino.restaurants} Restaurants</Typography>
                      </div>
                      <div className="quick-info-item">
                        <BarIcon />
                        <Typography>{hotel.casino.bars} Bars</Typography>
                      </div>
                      <div className="quick-info-item">
                        <HotelIcon />
                        <Typography>{hotel.roomCount} Rooms</Typography>
                      </div>
                      <div className="quick-info-item">
                        <EventIcon />
                        <Typography>{hotel.casino.entertainmentVenues} Entertainment Venues</Typography>
                      </div>
                    </div>
                  </Paper>

                  <Paper className="hotel-section price-section">
                    <Typography variant="h6" gutterBottom>Price Range</Typography>
                    <Typography variant="h4" className="price-range">
                      {hotel.priceRange.min} - {hotel.priceRange.max} {hotel.priceRange.currency}
                    </Typography>
                    <Typography variant="caption" className="price-caption">
                      per night
                    </Typography>
                    
                  </Paper>

                  <Paper className="hotel-section additional-info-section">
                    <Typography variant="h6" gutterBottom>Additional Information</Typography>
                    <div className="info-grid">
                      <div className="info-item">
                        <Typography variant="body2">
                          <strong>Dress Code:</strong> {hotel.casino.dresscode}
                        </Typography>
                      </div>
                      <div className="info-item">
                        <Typography variant="body2">
                          <strong>Minimum Age:</strong> {hotel.casino.minimumAge}
                        </Typography>
                      </div>
                      <div className="info-item">
                        <Typography variant="body2">
                          <strong>Established:</strong> {hotel.casino.yearEstablished}
                        </Typography>
                      </div>
                      {hotel.casino.lastRenovated && (
                        <div className="info-item">
                          <Typography variant="body2">
                            <strong>Last Renovated:</strong> {hotel.casino.lastRenovated}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}

          {/* Reviews Tab */}
          {activeTab === 1 && (
            <div className="tab-content reviews-section">
              {hotel.reviews && hotel.reviews.length > 0 ? (
                [...hotel.reviews]
                  .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                  .map((review) => (
                  <Paper key={review._id} className="review-card">
                    <div className="review-header">
                      <div className="review-user">
                        <div className="user-avatar">
                          {review.user.username.charAt(0).toUpperCase()}
                        </div>
                        <Typography variant="subtitle1">{review.user.username}</Typography>
                        <Chip
                          size="small"
                          className="date-chip"
                          label={new Date(review.createdAt).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        />
                      </div>
                      <div className="review-rating">
                        {[...Array(5)].map((_, index) => (
                          <StarIcon
                            key={index}
                            fontSize="small"
                            className={`star-icon ${index < review.rating ? 'star-filled' : 'star-empty'}`}
                            style={{ animationDelay: `${index * 0.1}s` }}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="review-content">
                      <blockquote className="review-quote">
                        {review.comment}
                      </blockquote>
                    </div>
                  </Paper>
                ))
              ) : (
                <Typography variant="body1" align="center" sx={{ py: 4 }}>
                  No reviews yet. Be the first to review this hotel!
                </Typography>
              )}
            </div>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default HotelDetailPage; 