import { useEffect } from 'react';

export const useLocalStorageInit = () => {
  useEffect(() => {
    // Set initial preferences if not already set
    if (!localStorage.getItem('userPreferences')) {
      const initialPreferences = {
        theme: 'light',
        currency: 'EUR',
        language: navigator.language || 'en',
        notifications: true,
        lastVisit: new Date().toISOString(),
        viewMode: 'grid',
        sortPreference: 'rating'
      };
      localStorage.setItem('userPreferences', JSON.stringify(initialPreferences));
    }

    // Set analytics data
    const visitCount = parseInt(localStorage.getItem('visitCount') || '0');
    localStorage.setItem('visitCount', (visitCount + 1).toString());
    localStorage.setItem('lastVisitDate', new Date().toISOString());

    // Set session data
    const sessionId = Math.random().toString(36).substring(2);
    sessionStorage.setItem('sessionId', sessionId);
    sessionStorage.setItem('sessionStartTime', new Date().toISOString());

    // Set feature flags
    if (!localStorage.getItem('featureFlags')) {
      const featureFlags = {
        showNewReviews: true,
        enableComments: true,
        showRatings: true,
        enableSharing: true,
        showOffers: true
      };
      localStorage.setItem('featureFlags', JSON.stringify(featureFlags));
    }

    // Set user region based on browser language
    if (!localStorage.getItem('userRegion')) {
      localStorage.setItem('userRegion', navigator.language.split('-')[1] || 'US');
    }
  }, []);
}; 