import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './FooterPages.css';

const TermsPage: React.FC = () => {
  return (
    <Container maxWidth="lg" className="footer-page-container">
      <Typography variant="h3" component="h1" className="page-title">
        Terms & Conditions
      </Typography>

      <Box className="content-section">
        <Typography variant="h5" gutterBottom>1. Acceptance of Terms</Typography>
        <Typography paragraph>
          By accessing and using Emerald Escape, you accept and agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.
        </Typography>

        <Typography variant="h5" gutterBottom>2. Service Description</Typography>
        <Typography paragraph>
          Emerald Escape is an informational platform providing reviews, listings, and news about casino hotels. We do not process bookings or payments. All information is for reference purposes only.
        </Typography>

        <Typography variant="h5" gutterBottom>3. User Accounts</Typography>
        <Typography paragraph>
          Users must provide accurate, current, and complete information during registration. Account holders are responsible for maintaining the confidentiality of their account credentials and any content they submit.
        </Typography>

        <Typography variant="h5" gutterBottom>4. Content and Reviews</Typography>
        <Typography paragraph>
          All content on our platform, including reviews, listings, and blog posts, is for informational purposes only. While we strive for accuracy, we cannot guarantee the completeness or reliability of any information presented.
        </Typography>

        <Typography variant="h5" gutterBottom>5. Third-Party Links</Typography>
        <Typography paragraph>
          Our platform may contain links to third-party websites, including hotel booking platforms. We are not responsible for the content, accuracy, or practices of these external sites.
        </Typography>

        <Typography variant="h5" gutterBottom>6. Limitation of Liability</Typography>
        <Typography paragraph>
          Emerald Escape shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use of our platform or the information provided therein.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsPage; 