import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Divider, Paper } from '@mui/material';
import { Warning, Timer, Block, Help, Casino, Security, Phone, Language } from '@mui/icons-material';
import './FooterPages.css';

const ResponsibleGamingPage: React.FC = () => {
  return (
    <Container maxWidth="lg" className="footer-page-container">
      <Typography variant="h3" component="h1" className="page-title">
        Responsible Gaming
      </Typography>

      <Box className="content-section">
        <Typography variant="h5" gutterBottom>Our Commitment</Typography>
        <Typography paragraph>
          At Emerald Escape, we are committed to promoting responsible gaming practices. While we provide reviews and information about casino hotels, we believe it's crucial to raise awareness about responsible gambling and provide resources for those seeking assistance.
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h5" gutterBottom>Understanding Problem Gambling</Typography>
        <Typography paragraph>
          Problem gambling can affect anyone, regardless of age, gender, or background. It's important to recognize the signs early and seek help when needed.
        </Typography>

        <Paper elevation={3} sx={{ p: 3, mb: 4, bgcolor: '#f8f9fa' }}>
          <Typography variant="h6" gutterBottom>Warning Signs:</Typography>
          <List>
            <ListItem>
              <ListItemIcon><Warning color="error" /></ListItemIcon>
              <ListItemText 
                primary="Financial Issues" 
                secondary="Spending more money than you can afford on gambling"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><Timer color="error" /></ListItemIcon>
              <ListItemText 
                primary="Time Management" 
                secondary="Gambling for longer periods than intended, neglecting responsibilities"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><Block color="error" /></ListItemIcon>
              <ListItemText 
                primary="Relationship Impact" 
                secondary="Gambling affecting personal relationships, work, or daily life"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><Casino color="error" /></ListItemIcon>
              <ListItemText 
                primary="Chase Losses" 
                secondary="Trying to recover losses by gambling more"
              />
            </ListItem>
          </List>
        </Paper>

        <Typography variant="h5" gutterBottom>Responsible Gaming Tips</Typography>
        <List>
          <ListItem>
            <ListItemIcon><Security color="primary" /></ListItemIcon>
            <ListItemText 
              primary="Set Limits" 
              secondary="Establish strict time and money limits before playing"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon><Timer color="primary" /></ListItemIcon>
            <ListItemText 
              primary="Take Breaks" 
              secondary="Regular breaks help maintain perspective and control"
            />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>Resources and Support</Typography>
        <Paper elevation={3} sx={{ p: 3, bgcolor: '#f8f9fa' }}>
          <Typography variant="subtitle1" gutterBottom>
            If you or someone you know needs support, these resources are available 24/7:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon><Phone color="primary" /></ListItemIcon>
              <ListItemText 
                primary="National Problem Gambling Helpline" 
                secondary="1-800-522-4700"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><Language color="primary" /></ListItemIcon>
              <ListItemText 
                primary="Gamblers Anonymous" 
                secondary="www.gamblersanonymous.org"
              />
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default ResponsibleGamingPage; 