import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, CircularProgress, Box, Paper } from '@mui/material';
import { Casino as CasinoIcon } from '@mui/icons-material';
import HotelCard from './HotelCard';
import FilterBar from './FilterBar';
import { hotelService } from '../services/hotelService';
import type { Hotel } from '../services/hotelService';
import './HotelList.css';

const HotelList: React.FC = () => {
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const [filteredHotels, setFilteredHotels] = useState<Hotel[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState(false);
  const [error, setError] = useState<string>('');
  
  // Filter states - set rating-desc as default sort
  const [selectedSort, setSelectedSort] = useState('rating-desc');
  const [selectedCountry, setSelectedCountry] = useState('');

  const countries = hotels
    .reduce((acc: Array<{ code: string; name: string }>, hotel) => {
      if (!acc.some(country => country.name === hotel.country)) {
        acc.push({
          code: hotel.country,
          name: hotel.country
        });
      }
      return acc;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

  const applyFilters = () => {
    setSorting(true);
    setTimeout(() => {
      let result = [...hotels];

      // Apply country filter using full name
      if (selectedCountry) {
        result = result.filter(hotel => hotel.country === selectedCountry);
      }

      // Apply sorting
      switch (selectedSort) {
        case 'rating-desc':
          result.sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));
          break;
        case 'price-asc':
          result.sort((a, b) => a.priceRange.min - b.priceRange.min);
          break;
        case 'price-desc':
          result.sort((a, b) => b.priceRange.min - a.priceRange.min);
          break;
        case 'name-asc':
          result.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'name-desc':
          result.sort((a, b) => b.name.localeCompare(a.name));
          break;
      }

      setFilteredHotels(result);
      setSorting(false);
    }, 300); // Small delay to show loading state
  };

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const data = await hotelService.getAllHotels();
        setHotels(data);
        // Apply initial sort (rating-desc)
        const sortedData = [...data].sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));
        setFilteredHotels(sortedData);
      } catch (err) {
        setError('Failed to load hotels');
        console.error('Error fetching hotels:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchHotels();
  }, []);

  useEffect(() => {
    if (hotels.length > 0) {
      applyFilters();
    }
  }, [selectedSort, selectedCountry]);

  if (loading || sorting) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error" align="center" gutterBottom>
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <div className="hotel-list-page">
      <Container maxWidth="lg">
        <Box className="page-header" mb={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Casino Hotels
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Discover our collection of {hotels.length} premium casino hotels worldwide. 
            Use the filters below to find your perfect destination.
          </Typography>
        </Box>

        <FilterBar
          countries={countries}
          onSortChange={setSelectedSort}
          onCountryChange={setSelectedCountry}
          selectedSort={selectedSort}
          selectedCountry={selectedCountry}
        />
        
        <Box mb={2}>
          <Typography variant="body2" color="text.secondary">
            Showing {filteredHotels.length} of {hotels.length} hotels
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {filteredHotels.map((hotel) => (
            <Grid item xs={12} sm={6} md={4} key={hotel._id}>
              <HotelCard hotel={hotel} showReviewQuote={false} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default HotelList; 