import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import HomePage from './components/HomePage';
import BlogPostPage from './components/BlogPostPage';
import Footer from './components/Footer';
import AllBlogPosts from 'components/AllBlogPosts';
import OffersPage from './components/OffersPage';
import HotelDetailPage from './components/HotelDetailPage';
import './App.css';
import HotelList from 'components/HotelList';
import RegisterForm from './components/RegisterForm';
import LoginForm from 'components/LoginForm';
import { AuthProvider } from './contexts/AuthContext';
import PrivacyPage from 'components/PrivacyPage';
import TermsPage from 'components/TermsPage';
import ResponsibleGamingPage from 'components/ResponsibleGamingPage';
import FAQPage from 'components/FAQPage';
import { useLocalStorageInit } from './hooks/useLocalStorage';
import { SecurityProvider } from './contexts/SecurityContext';
import ContactForm from './components/ContactForm';

const App: React.FC = () => {
  useLocalStorageInit();

  return (
    <SecurityProvider>
      <AuthProvider>
        <Router>
          <div className="App">
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/blog/:slug" element={<BlogPostPage />} />
              <Route path="/blog" element={<AllBlogPosts />} />
              <Route path="/offers" element={<OffersPage />} />
              <Route path="/hotels/:slug" element={<HotelDetailPage />} />
              <Route path="/hotels" element={<HotelList />} />
              <Route path="/register" element={<RegisterForm />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/responsible-gaming" element={<ResponsibleGamingPage />} />
              <Route path="/contact" element={<ContactForm />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </AuthProvider>
    </SecurityProvider>
  );
};

export default App;
