import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './FooterPages.css';

const PrivacyPage: React.FC = () => {
  return (
    <Container maxWidth="lg" className="footer-page-container">
      <Typography variant="h3" component="h1" className="page-title">
        Privacy Policy
      </Typography>

      <Box className="content-section">
        <Typography variant="h5" gutterBottom>Information We Collect</Typography>
        <Typography paragraph>
          We collect information that you provide directly to us when creating an account, including name and email address. We also automatically collect certain information about your device and how you interact with our platform through cookies and similar technologies.
        </Typography>

        <Typography variant="h5" gutterBottom>How We Use Your Information</Typography>
        <Typography paragraph>
          We use the collected information to provide our review and information services, personalize your experience, send newsletters (with your consent), analyze platform usage, and improve our content and services.
        </Typography>

        <Typography variant="h5" gutterBottom>Information Sharing</Typography>
        <Typography paragraph>
          We share your information with service providers who assist in our operations (such as analytics providers). We may also share information to comply with legal obligations or protect our rights. We do not sell your personal information to third parties.
        </Typography>

        <Typography variant="h5" gutterBottom>Cookies and Tracking</Typography>
        <Typography paragraph>
          We use cookies and similar tracking technologies to analyze trends, administer the website, track users' movements around the site, and gather demographic information about our user base as a whole.
        </Typography>

        <Typography variant="h5" gutterBottom>Data Security</Typography>
        <Typography paragraph>
          We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
        </Typography>

        <Typography variant="h5" gutterBottom>Your Rights</Typography>
        <Typography paragraph>
          You have the right to access, correct, or delete your personal information. You may also object to certain processing activities or request data portability. Contact us at privacy@emeraldescape.com to exercise these rights.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPage; 