import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const OffersPage: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={0} sx={{ p: 4, backgroundColor: 'transparent' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Special Offers & Promotions
        </Typography>
        
        <Typography variant="body1" paragraph sx={{ mb: 4 }}>
          At Casino Hotels, we're committed to bringing you exceptional value and unforgettable experiences. 
          Our special offers include exclusive room rates, dining credits, casino packages, and seasonal promotions 
          designed to enhance your stay at our world-class properties.
        </Typography>

        <Box 
          sx={{ 
            textAlign: 'center', 
            py: 6,
            px: 4,
            backgroundColor: '#f8f9fa',
            borderRadius: 2,
            mt: 4 
          }}
        >
          <LocalOfferIcon 
            sx={{ 
              fontSize: 80, 
              color: '#1976d2',
              mb: 2,
              opacity: 0.8
            }} 
          />
          
          <Typography variant="h6" gutterBottom>
            No Current Offers Available
          </Typography>
          
          <Typography variant="body1" color="text.secondary">
            We're currently updating our promotional offerings. Check back soon for exclusive deals 
            and special packages that will make your casino hotel experience even more memorable!
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default OffersPage; 