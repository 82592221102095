import React from 'react';
import { 
  Paper, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Box,
  Typography
} from '@mui/material';
import ReactCountryFlag from "react-country-flag";

interface FilterBarProps {
  countries: Array<{ code: string; name: string }>;
  onSortChange: (value: string) => void;
  onCountryChange: (value: string) => void;
  selectedCountry: string;
  selectedSort: string;
}

const FilterBar: React.FC<FilterBarProps> = ({
  countries,
  onSortChange,
  onCountryChange,
  selectedCountry,
  selectedSort,
}) => {
  return (
    <Paper elevation={2} sx={{ 
      p: 3, 
      mb: 4,
      backgroundColor: 'white',
      borderRadius: 2,
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
    }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Filter & Sort
      </Typography>
      <Box display="flex" justifyContent="center" gap={3}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={selectedSort}
            label="Sort By"
            onChange={(e) => onSortChange(e.target.value)}
          >
            <MenuItem value="rating-desc">Rating (High to Low)</MenuItem>
            <MenuItem value="price-asc">Price (Low to High)</MenuItem>
            <MenuItem value="price-desc">Price (High to Low)</MenuItem>
            <MenuItem value="name-asc">Name (A-Z)</MenuItem>
            <MenuItem value="name-desc">Name (Z-A)</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Country</InputLabel>
          <Select
            value={selectedCountry}
            label="Country"
            onChange={(e) => onCountryChange(e.target.value)}
          >
            <MenuItem value="">All Countries</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.name}>
                <Box display="flex" alignItems="center" gap={1}>
                  <ReactCountryFlag countryCode={country.code} svg />
                  {country.name}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Paper>
  );
};

export default FilterBar; 