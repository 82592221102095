import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  AutoAwesomeOutlined as LogoIcon
} from '@mui/icons-material';

const Footer: React.FC = () => {
  return (
    <Box sx={{ 
      bgcolor: '#143952',
      color: '#ecf0f1',
      pt: 6,
      pb: 3,
      mt: 'auto'
    }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 2,
              '& .logo-icon': {
                fontSize: '36px',
                color: '#7be7f5',
                filter: 'drop-shadow(0 0 2px #94d3f8)',
                transition: 'all 0.3s ease',
                marginRight: '8px'
              },
              '& .logo-icon:hover': {
                filter: 'drop-shadow(0 0 4px #94d3f8)'
              },
              '& .brand-name': {
                fontFamily: 'Orbitron, sans-serif',
                fontSize: '1.5rem',
                letterSpacing: '0.5px'
              }
            }}>
              <LogoIcon className="logo-icon" />
              <Typography className="brand-name">
                Emerald Escape
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Discover the world's finest casino hotels and resorts. Experience luxury, entertainment, and unforgettable gaming moments.
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton size="small" sx={{ color: '#ecf0f1' }}>
                <FacebookIcon />
              </IconButton>
              <IconButton size="small" sx={{ color: '#ecf0f1' }}>
                <TwitterIcon />
              </IconButton>
              <IconButton size="small" sx={{ color: '#ecf0f1' }}>
                <InstagramIcon />
              </IconButton>
              <IconButton size="small" sx={{ color: '#ecf0f1' }}>
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              
              <Link href="/hotels" color="inherit" underline="hover">All Hotels</Link>
              <Link href="/blog" color="inherit" underline="hover">Blog</Link>
              <Link href="/contact" color="inherit" underline="hover">Contact Us</Link>
              
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Popular Destinations
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Link href="/hotels" color="inherit" underline="hover">France</Link>
              <Link href="/hotels" color="inherit" underline="hover">Italy</Link>
              <Link href="/hotels" color="inherit" underline="hover">Germany</Link>
              <Link href="/hotels" color="inherit" underline="hover">Spain</Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Support
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Link href="/faq" color="inherit" underline="hover">FAQ</Link>
              <Link href="/terms" color="inherit" underline="hover">Terms & Conditions</Link>
              <Link href="/privacy" color="inherit" underline="hover">Privacy Policy</Link>
              <Link href="/responsible-gaming" color="inherit" underline="hover">Responsible Gaming</Link>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ 
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          mt: 4,
          pt: 3,
          textAlign: 'center'
        }}>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">
            © {new Date().getFullYear()} Emerald Escape. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 