import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FooterPages.css';

const FAQPage: React.FC = () => {
  const faqs = [
    {
      question: "How do I find a casino hotel?",
      answer: "You can browse our comprehensive list of casino hotels, read detailed reviews, and compare different properties. Each listing includes information about facilities, amenities, and location. For actual bookings, you'll be directed to the hotel's official website or booking partners."
    },
    {
      question: "Are the casino reviews genuine?",
      answer: "All reviews on our platform are carefully curated and verified. We combine expert opinions, guest experiences, and thorough research to provide accurate and helpful information about each property."
    },
    {
      question: "How often are offers updated?",
      answer: "We regularly update our offers section with the latest promotions, deals, and special events from various casino hotels. The offers are collected directly from the properties and their official channels."
    },
    {
      question: "How can I stay updated with new content?",
      answer: "You can sign up for our newsletter to receive updates about new casino hotel reviews, latest blog posts, and special offers. You can also follow us on social media for real-time updates."
    },
    {
      question: "What's included in the hotel listings?",
      answer: "Our hotel listings include detailed information about casino facilities, room types, dining options, entertainment offerings, location details, and general amenities. We also provide high-quality photos and comprehensive reviews."
    },
    {
      question: "How do I report incorrect information?",
      answer: "If you notice any outdated or incorrect information in our listings, please contact us through our support email. We strive to maintain accurate and up-to-date information across our platform."
    }
  ];

  return (
    <Container maxWidth="lg" className="footer-page-container">
      <Typography variant="h3" component="h1" className="page-title">
        Frequently Asked Questions
      </Typography>
      
      <Box className="faq-section">
        {faqs.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default FAQPage; 